import '../scss/promotion.scss';

/**
 * Initializes the promotion modal and handles the behavior for both desktop and mobile promotion buttons.
 * - Shows/hides the promotion button based on scroll position.
 * - Handles the button click to trigger the modal or remove the button.
 */
document.addEventListener('DOMContentLoaded', () => {
    const promotionModalElement = document.querySelector('.promotion-modal');
    let promotionModal = null;
    const promotionButton = document.querySelector('.btn-promotion');
    const promotionButtonMobile = document.querySelector('.btn-promotion-mobile');
    const isMobile = window.matchMedia('(max-width: 991px)').matches;

    if (promotionModalElement) {
        promotionModal = new window.bootstrap.Modal(promotionModalElement);
    }

    if (promotionButton) {
        /**
         * Handles the click event for the desktop promotion button.
         * If the close button inside the promotion button is clicked, the button is removed.
         * Otherwise, the modal is shown.
         *
         * @param {Event} event - The click event on the promotion button.
         */
        promotionButton.addEventListener('click', function (event) {
            const promotionCloseButton = promotionButton.querySelector('.btn-promotion__close');

            if (event.target === promotionCloseButton || promotionCloseButton.contains(event.target)) {
                promotionButton.remove();
            } else {
                promotionModal.show();
            }
        });
    }

    if (promotionButtonMobile && isMobile) {
        /**
         * Handles scroll events to show the mobile promotion button after scrolling down.
         */
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                promotionButtonMobile.classList.remove('d-none');
            } else {
                promotionButtonMobile.classList.add('d-none');
            }
        });

        /**
         * Handles the click event for the mobile promotion button.
         * If the close button inside the promotion button is clicked, the button is removed.
         * Otherwise, the modal is shown.
         *
         * @param {Event} event - The click event on the mobile promotion button.
         */
        promotionButtonMobile.addEventListener('click', function (event) {
            const promotionCloseButton = promotionButtonMobile.querySelector('.btn-promotion__close');

            if (event.target === promotionCloseButton || promotionCloseButton.contains(event.target)) {
                promotionButtonMobile.remove();
            } else {
                promotionModal.show();
            }
        });
    }
});